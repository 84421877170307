import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import VideoSec from '../../components/VideoSec';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import VitaProjekt from "../../images/vitaprojekt.png"
import Komora from "../../images/komora.jpg"
import Service from '../../components/Service';
import SvrhaUdruge from '../../components/SvrhaUdruge';
import FunFact from '../../components/FunFact';
import CauseSection from '../../components/CauseSection';
import TeamSection from '../../components/TeamSection';
import PartnerSection from '../../components/PartnerSection';



const SvrhaUdrugePage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Prijatelji, partneri, sponzori i donatori udruge'} pagesub={'O nama'} />
            {/* <VideoSec/> */}

            <section className=" section-padding">
                <div className="container" >
                    <div className="row align-items-center m-4">

                        <div className="col-12 m-4">

                            <div className='text-center'>
                                <img src={VitaProjekt} alt="Logo" className='img-fluid mb-3' width={262}/>
                                <p>
                                    VITA PROJEKT d.o.o. za projektiranje i savjetovanje u zaštiti okoliša<br />
                                    Ilica 191C, Zagreb • <a href="https://www.vitaprojekt.hr" target="_blank" rel="noreferrer">www.vitaprojekt.hr</a>
                                </p>
                            </div>

                            <div className='text-center' style={{marginTop: '4rem'}}>
                                <img src={Komora} alt="Logo" className='img-fluid mb-3'  width={200}/>
                                <p>
                                    HRVATSKA KOMORA INŽENJERA ŠUMARSTVA I DRVNE TEHNOLOGIJE<br />
                                    Prilaz Gjure Deželiċa 63, Zagreb • <a href="http://www.hkisdt.hr/" target="_blank" rel="noreferrer">www.hkisdt.hr</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <FunFact/> */}
            {/* <CauseSection/>
            <TeamSection/>
            <PartnerSection tNone={'title-none'}/> */}
            {/* <Footer /> */}
            <Scrollbar />
        </Fragment>
    )
};
export default SvrhaUdrugePage;
