import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import VideoSec from '../../components/VideoSec';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Service from '../../components/Service';
import SvrhaUdruge from '../../components/SvrhaUdruge';
import FunFact from '../../components/FunFact';
import CauseSection from '../../components/CauseSection';
import TeamSection from '../../components/TeamSection';
import PartnerSection from '../../components/PartnerSection';
import Tasks2022 from '../../components/Tasks2022';



const Tasks2022Page = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Program rada za 2023. godinu'} pagesub={'O nama'} />
            {/* <VideoSec/> */}

            <Tasks2022 />
            {/* <Service Fclass={'wpo-features-section-s2'} /> */}
            {/* <FunFact/> */}
            {/* <CauseSection/>
            <TeamSection/>
            <PartnerSection tNone={'title-none'}/> */}
            {/* <Footer /> */}
            <Scrollbar />
        </Fragment>
    )
};
export default Tasks2022Page;
