import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Service5 from '../../components/Service5';

const ServicePage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Ciljevi udruge'} pagesub={'Ciljevi'}/> 
            <Service5/>
            {/* <Footer/> */}
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;

