// images
import blogImg1 from "../images/blog/15.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";

import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";
import blogImg9 from "../images/blog/img-9.jpg";

import blogImg10 from "../images/blog/img-10.jpg";
import blogImg11 from "../images/blog/img-11.jpg";
import blogImg12 from "../images/blog/img-12.jpg";

import blogImg13 from "../images/blog/img-13.jpg";
import blogImg14 from "../images/blog/img-14.jpg";
import blogImg15 from "../images/blog/img-15.jpg";

import blogAvaterImg1 from "../images/blog/blog-avater/img-1.jpg";
import blogAvaterImg2 from "../images/blog/blog-avater/img-2.jpg";
import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";

import blogSingleImg1 from "../images/blog/15.jpg";
import blogSingleImg2 from "../images/blog/2.jpg";
import blogSingleImg3 from "../images/blog/3.jpg";

import blogSingleImg4 from "../images/blog/4.jpg";
import blogSingleImg5 from "../images/blog/5.jpg";
import blogSingleImg6 from "../images/blog/6.jpg";

import blogSingleImg7 from "../images/blog/7.jpg";
import blogSingleImg8 from "../images/blog/8.jpg";
import blogSingleImg9 from "../images/blog/9.jpg";

import blogSingleImg10 from "../images/blog/10.jpg";
import blogSingleImg11 from "../images/blog/11.jpg";
import blogSingleImg12 from "../images/blog/12.jpg";

import blogSingleImg13 from "../images/blog/13.jpg";
import blogSingleImg14 from "../images/blog/14.jpg";
import blogSingleImg15 from "../images/blog/15.jpg";

import timg7 from '../images/team/7.jpg'

const blogs = [
    {
        id: 1,
        title: 'Kratko izvješće o provedbi programa „Šuma je moj dom“',
        screens: blogImg1,
        description: `Edukacijski program ''Šuma je moj dom'' proveden je s ciljem promicanja šumske pedagogije kao
        važne sastavnice odgoja i obrazovanja i specifičnog komunikacijskog koncepta za održivi razvoj,
        zdrave načine života i očuvanje prirode .
        Program je ostvaren metodičkim pristupom šumske pedagogije, pri čemu su izvoditelji bili šumski
        edukatori udruge Centar za šumsku pedagogiju Silva.Rei Zagreb, a korisnici odgojno-obrazovne
        ustanove: Osnovna škola Luka-Sesvete i Osnovna škola Vugrovec - Kašina s ukupno 70 učenika
        u 4 razreda.
        Program je obuhvatio osposobljavanje 9 školskih učiteljica i provedbu 3 terenske radionice
        šumske pedagogije prema metodičkom priručniku i katalogu šumskih aktivnosti.
        Osposobljavanja i šumske radionice provedene su kako slijedi u prilogu.`,
        author: 'prof. dr. sc. Ivan Martinić',
        authorTitle: 'Silva Rei',
        authorImg: timg7,
        create_at: '21.06.2023.',
        blogSingleImg: blogSingleImg1,
        url: 'https://monachus.blob.core.windows.net/silvarei/Centar Silva.Rei_izvješće ŠP_Šuma je moj dom.pdf',
        blClass: 'format-standard-image',
    },
    {
        id: 2,
        title: 'Održan prvi seminar šumske pedagogije u Hrvatskoj',
        screens: "https://monachus.blob.core.windows.net/silvarei/seminar-01-2024.jpg",
        description: <div><p>Šumska pedagogija postaje sve vidljivija i u Hrvatskoj. Tome je svakako svoj doprinos dao i prvi
            seminar šumske pedagogije održan 29. svibnja 2024. na Fakultetu šumarstva i drvne tehnologije
            Sveučilišta u Zagrebu. Ovaj poticajni događaj za daljnji razvoj i afirmaciju šumske pedagogije u
            Hrvatskoj održan je u okviru projekta „Znanje iz šume za šumu - program šumske pedagogije za
            očuvanje šumskog bogatstva Grada Zagreba“. Seminar je organizirao <strong>Centar za šumsku pedagogiju
                Silva.Rei iz Zagreba</strong> uz pokroviteljstvo Ministarstva poljoprivrede Republike Hrvatske. Uz Fakultet
            šumarstva i drvne tehnologije (FŠDT) koji je bio suorganizator, seminar su podržali Hrvatska komora
            inženjera šumarstva i drvne tehnologije te Javna ustanova Park prirode Medvednica.</p>
            <p>Seminaru je prisustvovalo trideset pedagoških djelatnika iz osnovnih škola i predškolskih ustanova u
                nadležnosti Grada Zagreba, uključujući i studente Učiteljskog fakulteta Sveučilišta u Zagrebu i
                Fakulteta šumarstva i drvne tehnologije. Uvodno je u ime domaćina sudionike pozdravio prodekan
                FŠDT-a prof. dr. sc. Stjepan Posavec.</p>
            <p>Pročitajte više u PDF prilogu...</p>
        </div>,
        author: 'Anđela Antičević i Lara Zgrablić',
        authorTitle: 'Anđela Antičević i Lara Zgrablić',
        authorImg: blogAvaterImg2,
        create_at: '29.05.2024.',
        blogSingleImg: "https://monachus.blob.core.windows.net/silvarei/seminar-01-2024.jpg",
        url: 'https://monachus.blob.core.windows.net/silvarei/Prvi%20seminar%20%C5%A1umske%20pedagogije%20u%20Hrvatskoj.pdf',
        blClass: 'format-standard-image',
    },

    {
        id: 3,
        title: 'Izvješće o radu udruge za 2023. godinu',
        screens: "https://monachus.blob.core.windows.net/silvarei/Slika1.png",
        description: <div><ul style={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
            <ul style={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
                <li>donesena je odluka o pokretanje pripreme programa stručnog osposobljavanja za stjecanje statusa edukatora u programima šumske pedagogije</li>
                <li>donesen je Zaključak o formiranju radne skupine za pripremu programa stručnog osposobljavanja pristupnika u cilju stjecanje statusa edukator u području šumske pedagogije.</li>
                <li>donesen je Zaključak o formiranju koordinacije za provedbu projekta „Znanje iz šume za šumu – program šumske pedagogije za očuvanje šumskog bogatstva Grada Zagreba“ (natječaj Grada Zagreba)</li>
                <li>iznesen je prijedlog o organizaciji 1. seminara ŠP u Hrvatskoj; planirano za svibanj/lipanj 2024.</li>
            </ul>
        </ul>
            <p>Pročitajte više u PDF prilogu...</p>
        </div>,
        author: 'Dr.sc. Ivan Martinić',
        authorTitle: 'Silva Rei',
        authorImg: blogAvaterImg2,
        create_at: '24.06.2024.',
        // blogSingleImg: "https://monachus.blob.core.windows.net/silvarei/sof.jpg",
        url: 'https://monachus.blob.core.windows.net/silvarei/Centar%20za%20%C5%A1umsku%20pedagogiju_Izvje%C5%A1%C4%87e%20o%20radu%20za%202023..pdf',
        blClass: 'format-standard-image',
    },

    {
        id: 4,
        title: 'Izvješće o radu udruge za 2024. godinu',
        screens: "https://monachus.blob.core.windows.net/silvarei/seminar-01-2024.jpg",
        description: <div><ul style={{ listStyleType: 'disc', paddingLeft: '2rem' }}>
            <li>u 2024. održane tri sjednice Upravnog odbora udruge</li>
            <li>Uredu za udruge Grada Zagreba dostavljen je Zapisnik godi&scaron;nje skup&scaron;tine udruge.</li>
            <li>FINI je predan Financijski izvje&scaron;taj udruge za I-VI mjesec 2024. godine.</li>
            <li>provedeno osposobljavanja za stjecanje statusa &scaron;umski edukator - osnovni modul (A) za 14 kandidata iz Varaždinske županije; organizator seminara &ndash; Javna ustanova &bdquo;Priroda Varaždinske županije&ldquo; Varaždin.</li>
            <li>u okviru ugovora s &nbsp;Gradom Zagrebom (Gradski ured za gospodarstvo, ekolo&scaron;ku održivost i &nbsp;strategijsko planiranje) u cjelosti realizirane sve aktivnosti u projektu &bdquo;Znanje iz &scaron;ume za &scaron;umu &ndash; program &scaron;umske pedagogije za očuvanje &scaron;umskog bogatstva Grada Zagreba&ldquo;.</li>
            <li>u&nbsp;programu Europskog tjedna mobilnosti (ETM) u suradnji s Gradom Zagrebom održane dvije terenske radionice &Scaron;P za II .razrede O&Scaron; Kusto&scaron;ija u park &scaron;umi Grmo&scaron;čica 20. 09. 2024.</li>
            <li>objavljeni su članci vezani za najavu i prikaz 1. seminara &scaron;umske pedagogije u časopisu &bdquo;Hrvatske &scaron;ume&ldquo; (svibanj 2024, kolovoz 2024) te prikaz 1. seminara &scaron;umske pedagogije u časopisu &bdquo;&Scaron;umarski list&ldquo; 7-8/2024..</li>
            <li>na natječaj FZOUE prijavljen je projekt &bdquo;Biram &scaron;umu za učionicu! &ndash; program &scaron;umske pedagogije za očuvanje &scaron;umske bioraznolikosti&ldquo; u suradnji s partnerskim organizacijama JU Park prirode Medvednica i O&Scaron; Stjepana Radića Brestovac Orehovički.</li>
        </ul>
            <p>Pročitajte više u PDF prilogu...</p>
        </div>,
        author: 'Dr.sc. Ivan Martinić',
        authorTitle: 'Silva Rei',
        authorImg: blogAvaterImg2,
        create_at: '29.11.2024.',
        // blogSingleImg: "https://monachus.blob.core.windows.net/silvarei/sof.jpg",
        url: 'https://monachus.blob.core.windows.net/silvarei/Izvjesce%20o%20realizaciji%20Programa%20rada%20udruge%20u%202024.pdf',
        blClass: 'format-standard-image',
    },
    {
        id: 5,
        title: 'Prvi seminar šumske pedagogije u Hrvatskoj',
        screens: "https://monachus.blob.core.windows.net/silvarei/seminar-01-2024.jpg",
        description: <div>
            <p>Šumska pedagogija postaje sve vidljivija i u Hrvatskoj. Tome je svakako svoj doprinos dao i prvi seminar šumske pedagogije održan 29. svibnja 2024. na Fakultetu šumarstva i drvne tehnologije Sveučilišta u Zagrebu. Ovaj poticajni događaj za daljnji razvoj i afirmaciju šumske pedagogije u Hrvatskoj održan je u okviru projekta „Znanje iz šume za šumu - program šumske pedagogije za očuvanje šumskog bogatstva Grada Zagreba“. Seminar je organizirao Centar za šumsku pedagogiju Silva.Rei iz Zagreba uz pokroviteljstvo Ministarstva poljoprivrede Republike Hrvatske. Uz Fakultet šumarstva i drvne tehnologije (FŠDT) koji je bio suorganizator, seminar su podržali Hrvatska komora inženjera šumarstva i drvne tehnologije te Javna ustanova Park prirode Medvednica.</p>
            <p>Pročitajte više u PDF prilogu...</p>
        </div>,
        author: 'Anđela Antičević i Lara Zgrablić',
        authorTitle: 'Anđela Antičević i Lara Zgrablić',
        authorImg: blogAvaterImg2,
        create_at: '29.11.2024.',
        blogSingleImg: "https://monachus.blob.core.windows.net/silvarei/prviseminar.jpg",
        url: 'https://monachus.blob.core.windows.net/silvarei/Prvi%20seminar%20%C5%A1umske%20pedagogije%20u%20Hrvatskoj.pdf',
        blClass: 'format-standard-image',
    },
    // {
    //     id: '3',
    //     title: 'Give Education, It’s The Best Gift Ever.',
    //     screens: blogImg3,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg3,
    //     create_at: '18 AUG,21',
    //     blogSingleImg:blogSingleImg3,
    //     comment:'95',
    //     blClass:'format-video',
    // },
    // {
    //     id: '4',
    //     title: 'Let’s Save The Rare Birds From Hunting.',
    //     screens: blogImg4,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg1,
    //     create_at: '14 AUG,21',
    //     blogSingleImg:blogSingleImg4, 
    //     comment:'35',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '5',
    //     title: 'Don’t Shoot The Royel Tiger With Your Gun.',
    //     screens: blogImg5,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Konal Biry',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg2,
    //     create_at: '16 AUG,21',
    //     blogSingleImg:blogSingleImg5, 
    //     comment:'80',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '6',
    //     title: 'Don’t be a thief, save coral reef Stop Killing The Reef.',
    //     screens: blogImg6,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg3,
    //     create_at: '18 AUG,21',
    //     blogSingleImg:blogSingleImg6,
    //     comment:'95',
    //     blClass:'format-video',
    // },
    // {
    //     id: '7',
    //     title: 'Don’t treat oceans as universal garbage cans',
    //     screens: blogImg7,
    //     description: 'Don’t treat oceans as universal garbage cans',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg1,
    //     create_at: '14 AUG,21',
    //     blogSingleImg:blogSingleImg7, 
    //     comment:'35',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '8',
    //     title: 'The sun and the sand makes beaches beautiful',
    //     screens: blogImg8,
    //     description: 'The sun and the sand makes beaches beautiful',
    //     author: 'Konal Biry',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg2,
    //     create_at: '16 AUG,21',
    //     blogSingleImg:blogSingleImg8, 
    //     comment:'80',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '9',
    //     title: 'Don’t be a thief, save coral reef Stop Killing The Reef.',
    //     screens: blogImg9,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg3,
    //     create_at: '18 AUG,21',
    //     blogSingleImg:blogSingleImg9,
    //     comment:'95',
    //     blClass:'format-video',
    // },
    // {
    //     id: '10',
    //     title: 'Everything you need to know about COVID-19 vaccine',
    //     screens: blogImg10,
    //     description: 'Don’t treat oceans as universal garbage cans',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg1,
    //     create_at: '14 AUG,21',
    //     blogSingleImg:blogSingleImg10, 
    //     comment:'35',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '11',
    //     title: 'Coronavirus India cases and latest updates',
    //     screens: blogImg11,
    //     description: 'The sun and the sand makes beaches beautiful',
    //     author: 'Konal Biry',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg2,
    //     create_at: '16 AUG,21',
    //     blogSingleImg:blogSingleImg11, 
    //     comment:'80',
    //     blClass:'format-standard-image',
    // },
    // {
    //     id: '12',
    //     title: 'WHO issues clinical case definition of long COVID',
    //     screens: blogImg12,
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
    //     author: 'Jenefer Willy',
    //     authorTitle:'volunteer',
    //     authorImg:blogAvaterImg3,
    //     create_at: '18 AUG,21',
    //     blogSingleImg:blogSingleImg12,
    //     comment:'95',
    //     blClass:'format-video',
    // },

    {
        id: '13',
        title: 'Zaboravne vjeverice spašavaju šume ',
        screens: blogImg13,
        description: 'Vjeverice su zaslužne za tisuće novih stabala koja niknu svake godine diljem svijeta tako što jednostavno zaborave gdje su zakopale žireve koje su planirale kasnije grickati. Zaboravnost sivih vjeverica, kako navode kanadski zoolozi, spasit će američke šume od nestajanja. Naime, vjeverice nađene orahe i žireve ostavljaju kao rezervu za zimu sakrivajući ih u iskopanim rupama na različitim mjestima po šumi. Međutim, sive vjeverice, za razliku od smeđih, zaboravljaju gdje su zakopale orahe pa iz njih izrasta novo drveće. Tako zaboravnost sivih vjeverica ne dopušta da iščeznu američke šume, zaključuju znanstvenici. Smeđe vjeverice skupljaju orahe tako da stvaraju zimsku rezervu puneći duplje stabala/drveća, ali nikada ne zaboravljaju gdje su ostavile zalihu pa iz tih oraha nikad ništa ne izraste, budući ih do proljeća pojedu.',
        author: 'Ivan Martinić',
        authorTitle: 'prof.dr.sc.',
        authorImg: blogAvaterImg1,
        create_at: '04.01.2023.',
        blogSingleImg: blogSingleImg13,
        comment: '0',
        blClass: 'format-standard-image',
    },
    {
        id: '14',
        title: 'Indijanska legenda o lišću ',
        screens: blogImg14,
        description: 'Cherokee legenda kaže da je Veliki Duh dao svom drveću priliku, odnosno magijsku snagu da bude zeleno tijekom cijele godine. Međutim, tu će mogućnost dobiti samo ako ostane budno punih sedam dana i sedam noći. Dakako, nije sve drveće uspjelo ostati budno sedam dana i noći. Samo ono drveće koje je uspjelo ostati budno za nagradu je dobilo magične moći i zeleno je tijekom cijele godine, a ostalo drveće mijenja boju lišća koje na kraju i otpadne kako bi moglo spavati tijekom zime.',
        author: 'Ivan Martinić',
        authorTitle: 'prof.dr.sc.',
        authorImg: blogAvaterImg2,
        create_at: '04.01.2023.',
        blogSingleImg: blogSingleImg14,
        comment: '0',
        blClass: 'format-standard-image',
    },
    {
        id: '15',
        title: 'Zašto je učenje u šumi o šumi važno i po čemu je posebno? ',
        screens: blogImg15,
        description: ' „U šumi ćete primijetiti stvari koje nikada ne biste primijetili na nastavi. Vidimo kako učenik s poteškoćama hoda nizbrdo, onaj koji ne želi zaprljati ruke, koji se svega boji, razuzdanog koji mora prilagoditi svoj ritam ritmu skupine, vođu koji može biti proaktivan i nezanimljiv. Skupljanje vrećice lišća i donošenje na nastavu drugačije je od gledanja na fotografijama. Usitnjavanje komada kremena poput primitivnog čovjeka oduševljava. Osim toga, možete pronaći zadatke koji angažiraju sve učenike, od onih s poteškoćama u učenju do onih koji su posebno zainteresirani i sjajnih promatrača.“ [zapisao jedan učitelj].',
        author: '[jedan učitelj]',
        authorTitle: '',
        authorImg: blogAvaterImg3,
        create_at: '04.01.2023.',
        blogSingleImg: blogSingleImg15,
        comment: '0',
        blClass: 'format-video',
    },
];
export default blogs;